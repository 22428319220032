<template>
  <div id="user-view">
    <div class="text-center" v-if="loading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-form
      v-else
      ref="formRef"
      @submit.prevent="saveData"
    >


      <v-card class="mb-5 ">
        <v-card-title>Zielgruppe auswählen</v-card-title>
        <v-card-text class=" justify-center flex-column mb-0 ">
          <v-row class="">
            <v-col
              cols="12"
              md="6"
            >
<!--             <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-radio-group
                    v-model="form.mainData.land"
                    class="mt-0"
                    hide-details
                    @change="fetchNumberOfPotentialDatasets"
                  >
                    <div class="d-flex flex-wrap demo-space-x" row>
                      <v-radio
                        label="Deutschland"
                        color="primary"
                        value="de"
                      ></v-radio>
                      <v-radio
                        label="Österreich"
                        color="primary"
                        value="at"
                      ></v-radio>
                    </div>
                  </v-radio-group>
                </v-col>
              </v-row>-->
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-radio-group
                    v-model="form.mainData.suchtyp"
                    class="mt-0"
                    hide-details
                    @change="fetchNumberOfPotentialDatasets"
                  >
                    <div class="d-flex flex-wrap demo-space-x" row>
                      <v-radio
                        label="Suchradius"
                        color="primary"
                        value="radius"
                      ></v-radio>
                      <v-radio
                        label="PLZ-Bereich"
                        color="primary"
                        value="plzBereich"
                      ></v-radio>
                      <v-radio
                        label="PLZ-Liste"
                        color="primary"
                        value="plzListe"
                      ></v-radio>
                      <v-radio
                        v-if="form.mainData.land == 'de'"
                        label="Bundesland"
                        color="primary"
                        value="bundesland"
                      ></v-radio>
                    </div>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row v-if="form.mainData.suchtyp == 'radius'">
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-alert
                    dense
                    text
                    color="primary"
                    border="left"
                  >
                    Umkreis um konkrete Adresse (Straße + Nummer + PLZ + Ort)
                  </v-alert>
                </v-col>
                <v-col
                  cols="12"
                  md="10"
                  class="py-0"
                >
                  <v-autocomplete
                    v-model="form.mainData.adresse"
                    :addressLoading="addressLoading"
                    :items="addressItems"
                    item-text="text"
                    item-value="text"
                    :search-input.sync="addressSearch"
                    hide-details
                    label="Adresse eingeben"
                    outlined
                    dense
                    class="mb-3"
                    @change="fetchNumberOfPotentialDatasets"
                    :filter="fuzzyComparator"
                    @blur="updateAddressModel"
                    :rules="form.mainData.suchtyp == 'radius' ? [stringRules] : []"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="py-0 pl-0"
                >
                  <v-select
                    class="mb-3"
                    v-model="form.mainData.umkreisInKm"
                    hide-details="auto"
                    outlined
                    dense
                    :items="kilometers"
                    item-text="label"
                    item-value="value"
                    label="Umkreis"
                    @change="fetchNumberOfPotentialDatasets"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-else-if="form.mainData.suchtyp == 'plzBereich'">
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-alert
                    dense
                    text
                    color="primary"
                    border="left"
                  >
                    1 bis 5-stellige PLZ als Einzelangabe (z.B. 45 bis 46, oder 45690 bis 45701)
                  </v-alert>
                </v-col>
                <v-col
                  cols="6"
                  md="6"
                  class="py-0"
                >
                  <v-text-field
                    class="mb-3"
                    v-model="form.mainData.plzVon"
                    label="PLZ von"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="PLZ von"
                    @change="fetchNumberOfPotentialDatasets"
                    :rules="form.mainData.suchtyp == 'plzBereich' ? [stringRules] : []"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="6"
                  md="6"
                  class="py-0"
                >
                  <v-text-field
                    class="mb-3"
                    v-model="form.mainData.plzBis"
                    label="PLZ bis"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="PLZ bis"
                    @change="fetchNumberOfPotentialDatasets"
                    :rules="form.mainData.suchtyp == 'plzBereich' ? [stringRules] : []"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-else-if="form.mainData.suchtyp == 'bundesland'">
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-alert
                    dense
                    text
                    color="primary"
                    border="left"
                  >
                    Hier können Sie nach Bundesländern selektieren.
                  </v-alert>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="py-0 mb-5"
                >
                  <div class="attribute-list full ">

                    <v-card class="attribute-box" outlined>

                      <div class="attribute-content">
                        <v-card-title class="pl-0 pt-0" style="color:#736c7c;">Bundesland</v-card-title>
                        <div class="d-flex flex-wrap" style="gap: 5px;">
                          <v-chip
                            label
                            :color="isChipItemSelected(form.mainData.bundeslaender,bundesland) ? 'success' : ''"
                            small
                            v-for="bundesland in republicStates"
                            @click="form.mainData.bundeslaender = handleChipClick(form.mainData.bundeslaender,bundesland)"
                          >
                            <v-icon small left>{{icons.mdiCheckCircle}}</v-icon>
                            {{ bundesland }}
                          </v-chip>
                        </div>
                      </div>
                    </v-card>

                  </div>
                </v-col>

              </v-row>
              <v-row v-else>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-alert
                    dense
                    text
                    color="primary"
                    border="left"
                  >
                    Geben Sie hier eine Liste von PLZs an.
                  </v-alert>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="py-0 mb-5"
                >
                  <v-textarea
                    v-model="form.mainData.plzListe"
                    outlined
                    label="PLZ-Liste"
                    rows="7"
                    hide-details="auto"
                    placeholder="Mit Zeilenumbruch trennen"
                    @change="fetchNumberOfPotentialDatasets"
                    :rules="form.mainData.suchtyp == 'plzListe' ? [stringRules] : []"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="justify-end fill-height"
            >
              <vue-apex-charts
                id="chart-sales-overview"
                :options="chartOptions"
                :key="chartOptionsKey"
                :series="chartData"
                class="d-flex align-center justify-center"
                width="40%"
              ></vue-apex-charts>

              <v-alert
                v-if="chartOptionsKey == 'error'"
                dense
                text
                color="error"

                class="text-center"
              >
                Ihre Auswahl muss mindestens 350 Empfänger erreichen.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class=" justify-center flex-column mb-0 ">



          <v-row>
            <v-col
              cols="12"
              md="6"
            >
            <div class="attribute-list fill-height">
              <v-card class="attribute-box attribute-box-gender" outlined>
                <div class="attribute-icon">
                  <v-icon size="60" :color="chipIconColor()">{{icons.mdiGenderMaleFemale}}</v-icon>
                </div>

                <div class="attribute-content">
                  <v-card-title class="pl-0 pt-0" style="color:#736c7c;">Geschlecht</v-card-title>
                  <div class="d-flex" style="gap: 50px;">
                    <template v-for="gender in genders">
                      <v-checkbox
                        v-model="form.mainData.geschlechter"
                        :value="gender.value"
                        :label="gender.label"
                        dense
                        hide-details
                        @click="fetchNumberOfPotentialDatasets"
                      ></v-checkbox>
                    </template>
                  </div>
                </div>
              </v-card>
            </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
            <div class="attribute-list fill-height">
              <v-card class="attribute-box" outlined>
                <div class="attribute-icon">
                  <v-icon size="60" :color="chipIconColor()">{{icons.mdiCashMultiple}}</v-icon>
                </div>
                <div class="attribute-content">
                  <v-card-title class="pl-0 pt-0" style="color:#736c7c;">Kaufkraft</v-card-title>
                  <div class="d-flex flex-wrap" style="gap: 5px;">
                    <v-chip
                      label
                      :color="isChipItemSelected(form.mainData.kaufkraftStufen,purchasingPower.value) ? 'success' : ''"
                      small
                      v-for="purchasingPower in purchasingPowers"
                      @click="form.mainData.kaufkraftStufen = handleChipClick(form.mainData.kaufkraftStufen,purchasingPower.value)"
                    >
                      <v-icon small left>{{icons.mdiCheckCircle}}</v-icon>
                      {{ purchasingPower.label }}
                    </v-chip>
                  </div>
                </div>
              </v-card>
            </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
            <div class="attribute-list fill-height">
            <v-card class="attribute-box" outlined>
              <div class="attribute-icon">
                <v-icon size="60" :color="chipIconColor()">{{icons.mdiAccountGroup}}</v-icon>
              </div>
              <div class="attribute-content">
                <v-card-title class="pl-0 pt-0" style="color:#736c7c;">Alter</v-card-title>
                <div class="d-flex" style="gap: 10px;">
                  <v-text-field type="number" label="Von" outlined hide-details dense v-model="altersgruppe[0]" @change="fetchNumberOfPotentialDatasets" @input="fetchNumberOfPotentialDatasets"></v-text-field>
                  <v-text-field type="number" label="Bis" outlined hide-details dense v-model="altersgruppe[1]" @change="fetchNumberOfPotentialDatasets" @input="fetchNumberOfPotentialDatasets"></v-text-field>
                </div>
              </div>
            </v-card>
            </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
            <div class="attribute-list fill-height">
            <v-card class="attribute-box" outlined>
              <div class="attribute-icon">
                <v-icon size="60" :color="chipIconColor()">{{icons.mdiHomeCity}}</v-icon>
              </div>
              <div class="attribute-content">
                <v-card-title class="pl-0 pt-0" style="color:#736c7c;">Gebäudetyp</v-card-title>
                <div class="d-flex">
                  <div class="d-flex flex-wrap" style="gap: 5px;">
                    <v-chip
                      label
                      :color="isChipItemSelected(form.mainData.haustypen,houseType.value) ? 'success' : ''"
                      small
                      v-for="houseType in houseTypes"
                      @click="form.mainData.haustypen = handleChipClick(form.mainData.haustypen,houseType.value)"
                    >
                      <v-icon small left>{{icons.mdiCheckCircle}}</v-icon>
                      {{ houseType.label }}
                    </v-chip>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
          </v-col>
          </v-row>
        </v-card-text>
      </v-card>

<!--      <CostEstimateCard :totalSums="totalSums" v-if="chartOptionsKey == 'ok'"/>-->



      <v-card class="mb-5" v-if="chartOptionsKey == 'ok'">
        <v-row class="ma-0 pa-0">
          <v-col
            cols="12"
            md="12"
            lg="12"
            class="pa-0"
          >
            <v-card-title>Selektion speichern</v-card-title>
            <v-card-text>
              <v-text-field
                v-model="form.mainData.titel"
                label="Bitte Name für die Zielgruppen-Selektion vergeben"
                type="text"
                outlined
                dense
                hide-details="auto"

                :rules="[stringRules]"
                :error-messages="errorMessages.titel"
              ></v-text-field>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
      <v-row class="mt-5 ">
        <v-col cols="12" md="6" class="align-content-end">
          <router-link :to="'/zielgruppe'">
            <v-btn
              color="secondary"
              class="mx-2"
            >
              Zurück
            </v-btn>
          </router-link>
          <v-btn color="accent" type="submit" :disabled="chartOptionsKey == 'error'">
            Speichern
          </v-btn>
        </v-col>
        <v-col cols="12" md="6">
          <MisTerms/>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import {onMounted, ref, watch, getCurrentInstance,nextTick} from '@vue/composition-api'
import router from '@/router'
import {
  mdiHomeOutline,
  mdiEmailOutline,
  mdiLockOutline,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiBullseyeArrow,
  mdiCloseCircleOutline,
  mdiCashMultiple,
  mdiCheckCircle,
  mdiHomeCity,
  mdiGenderMaleFemale,
  mdiAccountGroup,
} from '@mdi/js'

import axios from "axios";
import {emailValidator, required} from "@core/utils/validation";
import {addAlpha, getVuetify} from "@core/utils";
import PushNotificationPreview from "@/views/push-notification/Preview";
import InlineDatePicker from "@/views/InlineDatePicker";
import CostEstimateCard from "@/views/CostEstimateCard";
import themeConfig from '@themeConfig'
import useHelper from "@/views/useHelper";
import MisTerms from "@/views/MisTerms";

export default {
  components: {
    MisTerms,
    CostEstimateCard,
    PushNotificationPreview,
    VueApexCharts: () => import('vue-apexcharts'),
    InlineDatePicker,

  },
  setup(props) {

    const userTab = ref(null)
    const tabs = ref([
      {icon: mdiHomeOutline, title: 'Übersicht', id: 'overview'},
    ])

    const {
      genders,
      purchasingPowers,
      houseTypes,
      convertToGermanFormat,
    } = useHelper()

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    const formRef = ref(null)
    const form = ref({})
    const republicStates = ref([])


    const numberOfPotentialDatasets = ref(0);
    const stringRules = (value) => {
      if (typeof value === "string") {
        value = value.trim();
      }
      return !!value || 'Bitte ausfüllen';
    }
    const errorMessages = ref({
      titel : ''
    });

    const updateAddressModel = () => {
      // Update the underlying data model with the current value of the input field
      form.value.mainData.adresse = form.value.mainData.adresse;
    };

    const validatePotentialAnalyzeInput = () => {
      let valid = false;
      if(form.value.mainData.suchtyp == 'radius' && form.value.mainData.adresse != ""){
        valid = true;
      }
      else if(form.value.mainData.suchtyp == 'plzBereich' && form.value.mainData.plzVon != "" && form.value.mainData.plzBis != ""){
        valid = true;
      }
      else if(form.value.mainData.suchtyp == 'plzListe' && form.value.mainData.plzListe != ""){
        valid = true;
      }
      else if(form.value.mainData.suchtyp == 'bundesland' && form.value.mainData.bundeslaender.length > 0){
        valid = true;
      }

      return valid;
    }

    const fetchNumberOfPotentialDatasets = () =>{
      if(validatePotentialAnalyzeInput()){
        let fd = new FormData();
        fd.append('aktion','fetchNumberOfPotentialDatasets');
        fd = appendFormMainData(fd);
        chartData.value = ["lädt..."];
        axios
          .post('/api/ajaxServer/',fd)
          .then(response => {
            numberOfPotentialDatasets.value = response.data.numberOfPotentialDatasets;
            /*totalSums.value = response.data.totalSums;*/
            form.value.mainData.anzahlEmpfaenger = numberOfPotentialDatasets.value;
            form.value.mainData.topPLZRegion = response.data.topPLZRegion;
            chartData.value = [numberOfPotentialDatasets.value];

            if(chartData.value < 350){
              chartOptions.value.colors = [$vuetify.theme.currentTheme.error]
              chartOptionsKey.value = 'error';
            }
            else{
              chartOptions.value.colors = [$vuetify.theme.currentTheme.primary]
              chartOptionsKey.value = 'ok';
            }
          })
          .catch(error => {
            vm.$toastr.e(error,error);
            loading.value = false;
          })
      }
    }

    const isChipItemSelected = (arr,value) => {
      if (arr.indexOf(value) > -1) {
        return true;
      }
      return false;
    }

    const handleChipClick = (arr,value) => {
      const index = arr.indexOf(value);
      if (index !== -1) {
        arr.splice(index, 1);
      } else {
        arr.push(value);
      }
      fetchNumberOfPotentialDatasets();
      return arr;
    }

    const chipIconColor = () => {
      return "rgba(208, 208, 208, 0.68)";
    };

    form.value = {
      mainData: {
        idPtZielgruppe: router.currentRoute.params.idPtZielgruppe,
        land: 'de',
        titel: '',
        anzahlEmpfaenger: 0,
        topPLZRegion: null,
        suchtyp : 'radius',
        adresse : '',
        strasse : '',
        hausnr : '',
        plz : '',
        ort : '',
        umkreisInKm : 5,
        plzVon : '',
        plzBis : '',
        plzListe : '',
        geschlechter: ["1","2","0"],
        bundeslaender: [],
        kaufkraftStufen: [
          '70401100',
          '70401200|70401300|70401400',
          '70401500',
          '70401600',
          '70401700'
        ],
        haustypen: [
          '70102100|70102200',
          '70102300|70102400|70102500',
          '70102600|70102700',
          '70102800'
        ],
        geburtsAlterVon: 18,
        geburtsAlterBis: 67,
      },
      aktion: (router.currentRoute.params.idPtZielgruppe !== undefined ? 'editTargetGroupCheck' : 'createTargetGroupCheck'),
    };

    const loading = ref(true)
    const altersgruppe = ref([18,67]);

    const kilometers = ref([
      {label: '1 km', value: 1},
      {label: '2 km', value: 2},
      {label: '3 km', value: 3},
      {label: '4 km', value: 4},
      {label: '5 km', value: 5},
      {label: '10 km', value: 10},
      {label: '15 km', value: 15},
      {label: '20 km', value: 20},
      {label: '50 km', value: 50},
      {label: '100 km', value: 100},
      {label: '1000 km', value: 1000},
    ]);

    onMounted(() => {

      axios.get('/api/ajaxServer/?aktion=fetchRepublicStates').then((response) => {
        republicStates.value = response.data.bundeslaender
      })

      if (form.value.aktion == 'editTargetGroupCheck') {

        axios.post('/api/zielgruppe/', {
          aktion: 'editTargetGroup',
          mainData: {
            idPtZielgruppe: router.currentRoute.params.idPtZielgruppe,
          }
        })
          .then((response) => {
            if (response.data.valid === true) {
              form.value.mainData = response.data.targetGroup;

              altersgruppe.value[0] = form.value.mainData.geburtsAlterVon;
              altersgruppe.value[1] = form.value.mainData.geburtsAlterBis;


              addressItems.value = [
                {"text" : form.value.mainData.adresse}
              ];

              fetchNumberOfPotentialDatasets();
            } else {
              response.data.errors.forEach(item => {
                let suffix = item.errorField != "" ? "(" + item.errorField + ")" : "";
                vm.$toastr.e(item.errorText, item.errorTitle + suffix);
              })
            }
            loading.value = false;
          })
          .catch((error) => {
            console.log(error)
            loading.value = true;
          })
      } else {
        loading.value = false;
      }
    })

    const appendFormMainData = (fd) => {

      if(form.value.mainData.idPtZielgruppe !== undefined){
        fd.append('mainData[idPtZielgruppe]', form.value.mainData.idPtZielgruppe);
      }
      fd.append('mainData[land]', form.value.mainData.land);
      fd.append('mainData[titel]', form.value.mainData.titel);
      fd.append('mainData[anzahlEmpfaenger]', form.value.mainData.anzahlEmpfaenger);
      fd.append('mainData[topPLZRegion]', JSON.stringify(form.value.mainData.topPLZRegion));
      fd.append('mainData[suchtyp]', form.value.mainData.suchtyp);
      fd.append('mainData[adresse]', form.value.mainData.adresse);
      fd.append('mainData[strasse]', form.value.mainData.strasse);
      fd.append('mainData[hausnr]', form.value.mainData.hausnr);
      fd.append('mainData[plz]', form.value.mainData.plz);
      fd.append('mainData[ort]', form.value.mainData.ort);
      fd.append('mainData[umkreisInKm]', form.value.mainData.umkreisInKm);
      fd.append('mainData[plzVon]', form.value.mainData.plzVon);
      fd.append('mainData[plzBis]', form.value.mainData.plzBis);
      fd.append('mainData[plzListe]', form.value.mainData.plzListe);
      fd.append('mainData[bundeslaender]', JSON.stringify(form.value.mainData.bundeslaender));
      fd.append('mainData[geburtsAlterVon]', altersgruppe.value[0]);
      fd.append('mainData[geburtsAlterBis]', altersgruppe.value[1]);
      fd.append('mainData[geschlechter]', JSON.stringify(form.value.mainData.geschlechter));
      fd.append('mainData[kaufkraftStufen]', JSON.stringify(form.value.mainData.kaufkraftStufen));
      fd.append('mainData[haustypen]', JSON.stringify(form.value.mainData.haustypen));

      return fd;
    }

    const saveData = () => {
      const isFormValid = formRef.value.validate()

      if (!isFormValid || chartOptionsKey.value == 'error' || (form.value.mainData.suchtyp == 'bundesland' && form.value.mainData.bundeslaender.length == 0))   {
        nextTick(() => {
          const firstErrorField = document.querySelector('.error--text');
          if (firstErrorField) {
            firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
        return
      }
      loading.value = true;

      let fd = new FormData();
      fd.append('aktion', form.value.aktion);
      fd = appendFormMainData(fd);

      axios
        .post('/api/zielgruppe/', fd)
        .then(response => {
          if (response.data.valid === true) {
            router.push('/zielgruppe', () => {
              vm.$toastr.s(response.data.message.text, response.data.message.title);
            })
          } else {
            response.data.errors.forEach(item => {
              errorMessages.value[item.errorField] = item.errorTitle;
              /*let suffix = item.errorField != "" ? "(" + item.errorField + ")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle + suffix);*/
            })
          }
          loading.value = false;
        })
        .catch(error => {
          vm.$toastr.e(error, error);
          loading.value = false;
        })
    }

    const removeSelectedItem = (item,index) => {
      item.splice(index, 1);
      fetchNumberOfPotentialDatasets();
    }

    const $vuetify = getVuetify()
    const chartOptionsKey = ref('ok');
    const chartOptions = ref({
      labels: ['Empfänger'],
      colors: [$vuetify.theme.currentTheme.primary],
      chart: {
        type: 'donut',
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '90%',
            labels: {
              show: true,
              name: {
                fontSize: '14px',
                offsetY: 25,
              },
              value: {
                fontSize: '2.125rem',
                fontWeight: 600,
                color: 'rgba(255,255,255)',
                offsetY: -15,
                formatter(value) {
                  console.log(value)
                  return `${value}`
                },
              },
              total: {
                show: false,
                label: 'Empfänger',
                color: 'rgba(255,255,255)',
                formatter(value) {
                  return `${value.globals.seriesTotals.reduce((total, num) => total + num)}`
                },
              },
            },
          },
        },
      },
    })
    const chartData = ref([""])

    const addressLoading = ref(false)
    const addressItems = ref([])
    const addressSearch = ref(null)
    const addressQuerySelections = query => {
      addressLoading.value = true

      let fd = new FormData();
      fd.append('aktion','fetchAddress');
      fd.append('mainData[adresse]', query);

      addressItems.value = [];
      axios
        .post('/api/ajaxServer/',fd)
        .then(response => {

          if(form.value.mainData.adresse){
            response.data.hits.push({"text" : form.value.mainData.adresse})
          }

          addressItems.value = response.data.hits;
          addressLoading.value = false
        })
    }
    const debouncedAddressQuerySelections = debounce(addressQuerySelections, 300, true);
    watch(addressSearch, query => {
      query && query !== form.value.mainData.adresse && debouncedAddressQuerySelections(query)

    })
    function debounce(func, delay, trailing) {
      let timeoutId;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeoutId);

        if (trailing) {
          timeoutId = setTimeout(() => {
            func.apply(context, args);
          }, delay);
        } else {
          func.apply(context, args);
        }
      };
    }

    const fuzzyComparator = (a, searchQuery, c) => {
      return true;
    }

    return {
      addressLoading,
      addressItems,
      addressSearch,
      userTab,
      tabs,
      fuzzyComparator,
      form,
      formRef,
      saveData,
      loading,
      genders,
      kilometers,
      purchasingPowers,
      republicStates,
      houseTypes,
      convertToGermanFormat,
      altersgruppe,
      removeSelectedItem,
      chartOptions,
      chartOptionsKey,
      chartData,
      stringRules,
      errorMessages,
      numberOfPotentialDatasets,
      fetchNumberOfPotentialDatasets,
      isChipItemSelected,
      handleChipClick,
      chipIconColor,
      updateAddressModel,
      icons: {
        mdiHomeOutline,
        mdiEmailOutline,
        mdiLockOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiBullseyeArrow,
        mdiCloseCircleOutline,
        mdiCashMultiple,
        mdiCheckCircle,
        mdiHomeCity,
        mdiGenderMaleFemale,
        mdiAccountGroup,
      },
      validators: {
        required,
        emailValidator,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.user-tabs {
  display: none;
}

#user-tabs-content {
  margin: 0 !important;
}

#chart-sales-overview {
  position: relative;
  top: -20px;

  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }

      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.sales-overview-stats-table {
  width: 100%;

  td {
    padding-bottom: 1rem;
  }

  .stats-dot {
    padding: 0.33rem;
  }

  // Set opacity of dots
  tr {
    &:nth-of-type(1) {
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.7;
        }
      }
    }

    &:nth-of-type(2) {
      td:nth-of-type(1) {
        .stats-dot {
          opacity: 0.5;
        }
      }

      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.15;
        }
      }
    }
  }
}

.v-card__subtitle, .v-card__text #chart-sales-overview {
  font-size: unset;
}

.v-file-input {
  flex-direction: row-reverse;
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}

.v-input__append-outer {
  white-space: nowrap;
}

.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}

.gamification-tree {
  top: 10%;
  right: 0;
}

.gamification-john-pose-2 {
  bottom: 0;
  right: 15%;
}

.gamification-tree-4 {
  bottom: 0;
  right: 30%;
}

.refreshIcon {
  position: absolute !important;
  right: 30px;
}

@media (max-width: 600px) {
  .gamification-tree,
  .gamification-tree-4 {
    display: none;
  }
  .gamification-john-pose-2 {
    right: 5%;
  }
}

@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}

@media (max-width: 400px) {
  .greeting-title {
    font-size: 1.2rem !important;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .gamification-john-pose-2 {
      right: initial;
      left: 15%;
    }

    .gamification-tree {
      right: initial;
      left: 0;
    }

    .gamification-tree-4 {
      left: 30%;
      right: initial;
    }
  }
}

.v-card > :first-child:not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > .v-card__progress + :not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}

.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0px;
}

.v-chip .v-icon{
  position: relative;
  right:-3px;
  top:1px;
}
.v-chip .v-icon:hover{
  color:firebrick;
}


.attribute-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  .theme--light.v-sheet--outlined {
    border: thin solid rgba(94, 86, 105, 0.33);
  }

  .attribute-box {
    padding: 30px;
    display: flex;
    gap: 10px;
    width: 100%;

    .attribute-icon {
      flex-shrink: 0;
      min-width: 70px;
      display: flex;
      align-items: start;
      justify-content: center;
    }

    .attribute-content {
      flex-grow: 1;
      padding-left: 10px;

      .v-chip{
        cursor:pointer;
      }

    }
  }

  @media screen and (min-width: 768px) {

    /*&:not(.full){
      grid-template-columns: 1fr 1fr;
    }*/

    display: grid;

    .attribute-box {
      padding: 20px;

    }
  }
}
.same-height {
  display: flex;
  align-items: stretch;
}
</style>
